<template>
  <div class="school">
    <!-- 左边的盒子 先只放一个页面 -->
    <div class="cnt-box">
      <div class="menu-box">
        <div class="title">学校管理 <i class="el-icon-caret-bottom"></i></div>
        <div class="list">教材版本设置</div>
      </div>
      <div class="cnt-msg-box">
        <div class="list-radio-box">
          <div class="list-title"><i>*</i>学段：</div>
          <el-radio-group v-model="levelId" @change="levelChange">
            <el-radio
              v-for="(v, i) in levelOptions"
              :key="i"
              :label="v.value"
              >{{ v.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="list-radio-box">
          <div class="list-title"><i>*</i>科目：</div>
          <el-radio-group v-if="levelId == 3" v-model="subjectId">
            <el-radio
              v-for="(v, i) in highSubjectOptions"
              :key="i"
              :label="v.value"
              >{{ v.label }}</el-radio
            >
          </el-radio-group>
          <el-radio-group v-if="levelId == 2" v-model="subjectId">
            <el-radio
              v-for="(v, i) in juniorSubjectOptions"
              :key="i"
              :label="v.value"
              >{{ v.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="list-radio-box">
          <div class="list-title"><i>*</i>年级：</div>
          <el-radio-group
            v-if="levelId == 3"
            v-model="gradeId"
            @change="gradeChange"
          >
            <el-radio
              v-for="(v, i) in highGradeList"
              :key="i"
              :label="v.value"
              >{{ v.label }}</el-radio
            >
          </el-radio-group>
          <el-radio-group
            v-if="levelId == 2"
            v-model="gradeId"
            @change="gradeChange"
          >
            <el-radio
              v-for="(v, i) in juniorGradeList"
              :key="i"
              :label="v.value"
              >{{ v.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="list-radio-box">
          <div class="list-title"><i>*</i>教材版本：</div>
          <el-radio-group v-model="textbookId">
            <el-radio
              v-for="(v, i) in textbookList"
              :key="i"
              :label="v.value"
              >{{ v.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <el-button
          class="btn-all"
          type="primary"
          :loading="loading"
          @click="bookSave"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { levelOptions, subjectOptions } from "@/core/util/constdata";
import {
  getGradeList,
  // getClassListByLevelAndYear,
} from "@/core/api/school/schoolgradeclass";
import { booklist, bookSave } from "@/core/api/school/otherSchool";
export default {
  name: "School",
  data() {
    return {
      levelId: "2",
      subjectId: 1,
      gradeId: 1,
      levelOptions: levelOptions(),
      subjectOptions: subjectOptions,
      gradeList: [],
      juniorGradeList: [],
      highGradeList: [],
      highSubjectOptions: [],
      juniorSubjectOptions: [],
      textbookList: [],
      textbookId: 0,
      loading: false,
    };
  },
  watch: {
    subjectId() {
      this.booklist();
    },
  },
  created() {
    this.getGradeList();
    this.setSubject();
    // console.log(this.levelOptions);
  },
  methods: {
    //保存
    bookSave() {
      let data = {
        bookId: this.textbookId,
        subjectId: this.subjectId,
        gradeId: this.gradeId,
      };
      this.loading = true;
      bookSave(data)
        .then(() => {
          this.loading = false;
          this.$message({
            message: "保存成功！",
            type: "success",
          });
          // console.log(res);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    levelChange(val) {
      if (this.levelId == 2) {
        this.gradeId = this.juniorGradeList[0].value;
      }
      if (this.levelId == 3) {
        this.gradeId = this.highGradeList[0].value;
      }
      if (val) {
        this.booklist();
      }
    },
    gradeChange() {
      this.booklist();
    },
    //获取教材版本列表
    booklist() {
      let data = {
        gradeId: this.gradeId,
        subjectId: this.subjectId,
        level: this.levelId,
      };
      this.textbookList = [];
      booklist(data).then((res) => {
        // console.log(res);
        res.data.data.forEach((item) => {
          this.textbookList.push({
            label: item.editionName,
            value: item.id,
            isUse: item.isUse,
          });
        });
        let bookArr = this.textbookList.filter((item) => item.isUse == 1);
        // console.log(bookArr);
        if (bookArr.length == 0) {
          this.textbookId = this.textbookList[0].value;
        } else {
          this.textbookId = bookArr[0].value;
        }
      });
    },
    setSubject() {
      this.highSubjectOptions = this.subjectOptions.filter(
        (i) => i.value != 11
      );
      this.juniorSubjectOptions = this.subjectOptions.filter(
        (i) => i.value != 6
      );
    },
    //获取班级列表
    getGradeList() {
      getGradeList().then((res) => {
        this.gradeList = res.data.data;
        this.highGradeList = [];
        this.juniorGradeList = [];
        res.data.data.forEach((item) => {
          if (item.level == 3) {
            this.highGradeList.push({
              label: item.year,
              value: item.id,
            });
          } else {
            this.juniorGradeList.push({
              label: item.year,
              value: item.id,
            });
          }
        });
        this.levelChange();
        this.booklist();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.school {
  background: #f4f4f4;
  // font-size: 18px;
  .el-button {
    margin-left: 108px;
  }
  .cnt-box {
    padding-top: 24px;
    padding-bottom: 40px;
    width: 1220px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    .cnt-msg-box {
      width: calc(100% - 204px);
      background: #ffffff;
      min-height: calc(100vh - 216px);
      padding: 24px;
      .list-radio-box {
        display: flex;
        align-items: flex-start;
        margin-bottom: calc(30px - 0.5em);
        // flex-wrap: wrap;
        .list-title {
          margin-right: 24px;
          line-height: 14px;
          width: 84px;
          text-align: right;
          white-space: nowrap;
          flex-shrink: 0; /*防止被压缩*/
          i {
            color: #ff2626;
            margin-right: 4px;
          }
        }
      }
    }
    .menu-box {
      width: 180px;
      background: #ffffff;
      min-height: calc(100vh - 216px);
      .list {
        color: #2474ed;
        padding: 24px 36px;
        font-size: 16px;
      }
      .title {
        padding: 24px;
        font-size: 16px;
        color: #161817;
        border-bottom: 1px solid #e1e1e1;
        i {
          color: #161817;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
